import {TierSubscriptionInfoRepresentation} from '@aztrix/sdk';

export class FetchCurrentTier {
  static readonly type = '[Tier] Fetch current Tier';
}

export class SetCurrentTier {
  static readonly type = '[Tier] Set current Tier';
  constructor(public currentTier: TierSubscriptionInfoRepresentation.CurrentTierEnum) {}
}
