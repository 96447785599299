import {Inject, Injectable, Optional} from '@angular/core';
import {AUTH_TOKEN, TierSubscriptionInfoRepresentation} from '@aztrix/sdk';
import {Store} from '@ngxs/store';
import {BehaviorSubject} from 'rxjs';

import {FetchCurrentTier, SetCurrentTier} from './tier.actions';
import {TierState} from './tier.state';

@Injectable({
  providedIn: 'root',
})
export class TierService {
  currentTier$ = this._store.select(TierState.currentTier);

  constructor(
    private _store: Store,
    @Optional() @Inject(AUTH_TOKEN) authToken: BehaviorSubject<string | undefined>
  ) {
    authToken.subscribe(() => {
      this._store.dispatch(FetchCurrentTier);
    });
  }

  setCurrentTier(currentTier: TierSubscriptionInfoRepresentation.CurrentTierEnum) {
    return this._store.dispatch(new SetCurrentTier(currentTier));
  }
}
