import {Injectable} from '@angular/core';
import {SettingsService, TierSubscriptionInfoRepresentation} from '@aztrix/sdk';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {tap} from 'rxjs';

import {Clear} from '../actions';
import {FetchCurrentTier, SetCurrentTier} from './tier.actions';
import {defaults, TierStateModel} from './tier-state.model';

@State<TierStateModel>({
  name: 'tier',
  defaults,
})
@Injectable()
export class TierState {
  constructor(private _settingsService: SettingsService) {}

  @Selector()
  static currentTier(
    state: TierStateModel
  ): TierSubscriptionInfoRepresentation.CurrentTierEnum | undefined {
    return state?.currentTier;
  }

  @Action(Clear)
  clear({setState}: StateContext<TierStateModel>) {
    setState({...defaults});
  }

  @Action(SetCurrentTier)
  setCurrentTier({patchState}: StateContext<TierStateModel>, {currentTier}: SetCurrentTier) {
    patchState({currentTier});
  }

  @Action(FetchCurrentTier)
  fetchCurrentTier({dispatch}: StateContext<TierStateModel>) {
    return this._settingsService
      .getAccountSettings()
      .pipe(
        tap((settings) =>
          dispatch(
            new SetCurrentTier(
              settings.tier || TierSubscriptionInfoRepresentation.CurrentTierEnum.FREE
            )
          )
        )
      );
  }
}
